<template>
  <div class="kefu-page list">
    <iframe class="kefu-iframe" v-if="kefuUrl !== undefined" :src="kefuUrl"></iframe>
  </div>
</template>
<script>
import { Button, Icon, Popup } from 'vant'
import { getKefuList } from '@/api/user'
import "@/utils/sdk.js"
import { getUser, getUserId } from '@/utils/auth';
export default {
  components: {
    Button,
    Icon,
    Popup
  },
  data() {
    return {
      keyword: '',
      kefuList: [{Name: '在线客服'}],
      kfData: {},
      // kefuUrl: '/#/kf2'
      kefuUrl: undefined,
      headerUrl: require('../../assets/imgs/header2.png'),
      kefuUrl1: require('../../assets/imgs/header.png'),
      kefuUrl2: require('../../assets/imgs/icon-kefu2.png'),
      linkUrl: require('../../assets/imgs/icon-link2.png'),
      sendUrl: require('../../assets/imgs/icon-send.png'),
      helpUrl: require('../../assets/imgs/icon-help.png'),
      showKefuModal: false,
      showSmallkefu: false,
      showBack: false
    }
  },
  mounted() {
    this.init()
  },
  beforeRouteLeave(to, from, next) {
    // 用户即将离开当前路由时执行
    try {
      _MEIQIA('hidePanel');
    } catch (e) {}
    console.log('用户确认离开');
    next();
  },
  methods: {
    runMeiqiaScript() {
      const script = document.createElement('script');
      script.async = true;
      script.charset = 'UTF-8';
      script.src = 'https://static.meiqia.com/dist/meiqia.js';
      document.head.appendChild(script);
      window._MEIQIA = window._MEIQIA || function() {
        (window._MEIQIA.a = window._MEIQIA.a || []).push(arguments);
      };
    },
    init() {
      getKefuList().then(res => {
        this.kefuList = res.data.Items
        let that = this
        if (this.kefuList.length > 0) {
          // 检查是否是livechatinc
          if(this.kefuList[0].Link.indexOf('lc.chat') > -1) {
            that.$router.push({
              name: 'kf2'
            })
            return
          }
          if(this.kefuList[0].Link.indexOf('chatlink.html') > -1) {
            let url = this.kefuList[0].Link
            if(url.toString().indexOf("http") == -1) {
              url = document.location.protocol + "//" + document.location.host + url
            }
            let link = url + '&remark=' + this.kefuList[0].Remark + '&ID=' + getUserId() + '&name=' + getUser() + '&username=' + getUser()
            this.kefuUrl = link
            // this.$router.back()
            return
          }
          if(this.kefuList[0].Link.indexOf('qiabot.html') > -1) {
            let url = this.kefuList[0].Link
            if(url.toString().indexOf("http") == -1) {
              url = document.location.protocol + "//" + document.location.host + url
            }
            let link = url + '&remark=' + this.kefuList[0].Remark + '&ID=' + getUserId() + '&name=' + getUser() + '&username=' + getUser()
            this.kefuUrl = link
            return
          }
          // tawk
          if(this.kefuList[0].Link.indexOf('tawk.to') > -1) {
            // 等待tawkLoaded加载完成
            let tmp = setInterval(function (){
              console.log("setInterval")
              debugger
              if (that.$store.state.tawkLoaded !== undefined && that.$tawkMessenger !== undefined) {
                try {
                  let user = getUser()
                  console.log("user",typeof user)
                  console.log("user",user !== undefined)
                  if(user !== undefined) {
                    that.$tawkMessenger.setAttributes({
                      name : user,
                      Email : user,
                      remark:that.kefuList[0].Remark,
                    });
                  }
                  that.$tawkMessenger.maximize();
                  clearInterval(tmp)
                  that.$tawkMessenger.$on('chatMinimized', () => {
                    that.$router.push({
                      name: 'index'
                    })
                  });

                }catch (e) {
                  console.log(e)
                }
              }
            },50)
            // this.$router.back()
            return
          }
          if(this.kefuList[0].Link.indexOf('kefu-global') > -1) {
            this.kfData = this.kefuList[0]
            this.chatwoot(this.kefuList[0].Link, this.kefuList[0].Token)
            return;
          }
          if(this.kefuList[0].Token.length > 0) {
            this.kefuUrl = '/#/kf2'
          } else {
            this.runMeiqiaScript()
            // this.kefuUrl = this.kefuList[0].Link
            // this.kefuUrl = this.kefuList[0].Link + '&metadata={"ID":"' +getUserId()+ '","name":"'+ getUser()+ '","username":"'+ getUser()+'","comment":"'+this.kefuList[0].Remark+'"}'
            // window.location.href = this.kefuUrl
            // 使用正则表达式匹配并提取eid参数的值
            const match = this.kefuList[0].Link.match(/eid=([^&]+)/);
            const eidValue = match ? match[1] : null;
            window._MEIQIA('entId', eidValue);
            window._MEIQIA('withoutBtn');
            window._MEIQIA('showPanel');
            window._MEIQIA('language','en')
            // 传递顾客信息
            _MEIQIA('metadata', {
              ID: getUserId(), // 美洽默认字段
              name: getUser(), // 美洽默认字段
              username: getUser(), // 美洽默认字段
              comment: this.kefuList[0].Remark, // 美洽默认字段
            });
            //点击关闭按钮后的回调
            _MEIQIA('beforeCloseWindow', function() {
              // alert('点击了关闭按钮');
              // 返回
              that.$router.push({
                name: 'index'
              })
            });
          }
        }
      })
    },
    to(Type,Link){
      let tmp = undefined
      let that = this
      if(Type == "chatwoot") {
        if(window.$chatwoot === undefined) {
          tmp = setInterval(function (){
            // that.chatwoot()
            if (window.$chatwoot !== undefined) {
              clearInterval(tmp)
              window.$chatwoot.toggle('open');
            }
          },50)
        } else {
          window.$chatwoot.toggle('open');
        }
      } else {
        switch (Type) {
          case "wechat":
            Link = "weixin://" + Link
            break;
          case "qq":
            Link = "mqq://"+ Link
            break;
          case "whatsapp":
            Link = "whatsapp://send?phone="+ Link
            break;
          case "telegram":
            Link = "tg://resolve?domain="+ Link
            break;
          case "facebook":
            Link = "fb://"+ Link
            break;
          case "twitter":
            Link = "twitter://"+ Link
            break;
          case "instagram":
            Link = "instagram://"+ Link
            break;
            case "line":
            Link = "https://line.me/R/"+ Link
            break;
        }
        this.toPath(Link)
      }
    },
    toPath(path) {
      let a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    chatwoot(url,token){
      let that = this
      window.chatwootSDK.run({
        websiteToken: token,
        baseUrl: url
      });
      that.inter = setInterval(function(){
        if(document.getElementsByClassName("woot-widget-bubble")[0] !== undefined) {
          that.show = true;
          clearInterval(that.inter);
          // console.log("window.localStorage.username")
          let js = {}
          try {
            js = JSON.parse(that.kfData.Remark)
          } catch (e) {
            console.log(e)
          }
          let obj = {
            name:"账号："+ that.kfData.Username + " 备注：" +that.kfData.UserRemark, // Name of the user
            email: "", // Email of the user
            Remark: that.kfData.UserRemark, // Email of the user
            // identifier_hash: that.kfData.IdentifierHash // Identifier Hash generated in the previous step
          }
          console.log(that.kfData.IdentifierHash)
          if(js.identifierHash !== undefined) {
            obj.identifier_hash = js.identifierHash
            window.$chatwoot.setUser(window.localStorage.username, obj);
          }
          window.$chatwoot.setLocale('en')
          window.$chatwoot.toggle('open');
          window.$chatwoot.toggleBubbleVisibility('hide');
        }
      },30);
    },
    showKefu() {
      this.showKefuModal = true
    },
    cancel() {
      this.showKefuModal = false
      this.showSmallkefu = true
    },
    back() {
      this.showBack = true
    },
    cancelBack() {
      this.showBack = false
    },
    ensureBack() {
      this.showBack = false
      this.showKefuModal = false
      this.showSmallkefu = false
    }
  }
}
</script>